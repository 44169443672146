export const APP_ROUTES = {
  dashboard: {
    label: "Dashboard",
    href: "/",
  },
  aspirantReports: () => {
    return {
      label: "Ordered Products",
      href: "/ordered-products",
    };
  },
  aspirantReport: (id: string) => {
    return {
      label: "GenDiscover Report",
      href: `/ordered-products/${id}`,
    };
  },
  samplerReport: (id: string) => {
    return {
      label: "Personality Sampler Report",
      href: `/ordered-products/${id}/personality-sampler-report`,
    };
  },
  aspirantBasicInfo: (id: string) => {
    return {
      label: "Aspirant Basic Info",
      href: `/ordered-products/${id}/aspirant-basic-info`,
    };
  },
  aspirantCareerPaths: (id: string) => {
    return {
      label: "Aspirant Career Paths",
      href: `/ordered-products/${id}/aspirant-career-paths`,
    };
  },
};
