import { Input } from "@mantine/core";
import { useState } from "react";

const SearchInput = ({
  search,
  setSearch,
}: {
  search: string | undefined;
  setSearch: (_: string) => void;
}) => {
  const [serchValue, setSearchValue] = useState<string | undefined>(search);
  return (
    <Input
      placeholder="Search"
      value={serchValue}
      onChange={(e: any) => {
        setSearchValue(e.target.value);
      }}
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          setSearch(e.target.value);
        }
      }}
      wrapperProps={{ style: { maxWidth: 300 } }}
    />
  );
};
export default SearchInput;
