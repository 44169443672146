import { DateRangePicker } from "@mantine/dates";
import { useEffect, useState } from "react";

const CustomDateRangePicker = ({
  value = [],
  placeholder,
  onChange,
  startDateKey = "startDate",
  endDateKey = "endDate",
}: {
  value: string[];
  placeholder: string;
  onChange: (_: any) => void;
  startDateKey?: string;
  endDateKey?: string;
}) => {
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);

  useEffect(() => {
    setDate(
      value?.[0] ? [new Date(value[0]), new Date(value[1])] : [null, null]
    );
  }, [value]);

  return (
    <DateRangePicker
      placeholder="Barcode Updated Date"
      value={date}
      onChange={(value) => {
        setDate(value);
        if (value[0] && value[1]) {
          onChange({
            [startDateKey]: value[0].toISOString(),
            [endDateKey]: value[1].toISOString(),
          });
        }
        if (!value[0] && !value[1]) {
          onChange({
            [startDateKey]: null,
            [endDateKey]: null,
          });
        }
      }}
    />
  );
};

export default CustomDateRangePicker;
