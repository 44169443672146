import { Header, ThemeIcon, Text, Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons";

import { useLayoutContext } from "contexts/main-layout-context";
import { useStyles } from "./styles";

export function PageHeader() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { pageTitle, goBackButtonLink } = useLayoutContext();

  const handleGoBack = () => {
    navigate(goBackButtonLink);
  };

  return (
    <Header height="62px" className={classes.header}>
      <Box
        sx={{
          display: "flex",
          gap: 10,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {goBackButtonLink !== "#" && (
          <ThemeIcon
            onClick={handleGoBack}
            sx={{ cursor: "pointer" }}
            variant="light"
          >
            <IconArrowLeft />
          </ThemeIcon>
        )}
        <Text component="h3">{pageTitle}</Text>
      </Box>
    </Header>
  );
}
