import { useAuth0 } from "@auth0/auth0-react";
import { AppShell, Container } from "@mantine/core";

import { NavbarNested } from "components/Navbar";
import { AppRoutes } from "Routes/AppRoutes";
import { AuthRoutes } from "Routes/AuthRoutes";
import { PageHeader } from "components/PageHeader";
import { ContentLoader } from "components/ContentLoader";
import { useLayoutContext } from "contexts/main-layout-context";

export function MainLayout() {
  const { isAuthenticated, isLoading } = useAuth0();
  useLayoutContext();

  if (isLoading) {
    return <ContentLoader />;
  }

  if (!isAuthenticated) {
    return (
      <Container sx={(theme) => ({ padding: theme.spacing.xl })}>
        <AuthRoutes />
      </Container>
    );
  }

  return (
    <AppShell
      navbar={<NavbarNested />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          width: "100%",
        },
      })}
    >
      <PageHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "90%",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }}>
          <AppRoutes />
        </div>
      </div>
    </AppShell>
  );
}
