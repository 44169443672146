import { useAuth0 } from '@auth0/auth0-react';

import { apiManager } from 'services/api-manager';

let interceptorId: number | null = null;
let tokenPromise: Promise<string> | null = null;

/**
 * This component is responsible for fetching the auth0 API token and sets an interceptor which will add the token to each request.
 * Because the auth token is returned asynchronous this component might hold up API calls made using apiManager until the token is resolved.
 * This component should always be mounted before all the other components which uses apiManager instance in order to get the token before calling any API using API manager.
 * @return {null}
 */
export const AccessTokenManager = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const requestInterceptor = async (requestConfig: any) => {
    const _token = await tokenPromise;
    requestConfig.headers['Authorization'] = `Bearer ${_token}`;
    return requestConfig;
  };

  const init = () => {
    tokenPromise = getAccessTokenSilently().catch((error) => {
      console.error(error);
      return '';
    });
  };

  const configureAPIManagerInstance = () => {
    const interceptorId = apiManager.interceptors.request.use(requestInterceptor);
    return interceptorId;
  };

  if (interceptorId === null && tokenPromise === null && isAuthenticated) {
    init();
    interceptorId = configureAPIManagerInstance();
  }

  return null;
};