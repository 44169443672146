import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  loaderOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  dataTable: {
    "& th": {
      backgroundColor: "#f5f5f5",
      fontSize: "15px !important",
      verticalAlign: "middle",
      textAlign: "center",
      "& svg": {
        color: "#5453ac",
        cursor: "pointer",
        verticalAlign: "middle",
        height: "25px",
        width: "25px",
      },
      "& .disabled": {
        color: "#4a5157",
      },
    },
    "& td": {
      verticalAlign: "top",
      fontSize: "15px !important",
    },
  },
}));

export default useStyles;
