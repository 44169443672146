import { Routes, Route } from "react-router-dom";

import { AspirantReportList } from "pages/Aspirants/ReportList";
import { AspirantReport } from "pages/Aspirants/AspirantReport/Report";
import { CounsellingSessionList } from "pages/CounsellingSessions";
import { NotFound } from "pages/ErrorsPage/404";
import { AspirantBasicInfo } from "pages/Aspirants/AspirantBasicInfo";
import { AspirantCareerPaths } from "pages/Aspirants/AspirantCareerPaths";
import { AspirantSamplerReport } from "pages/Aspirants/AspirantReport/viewSamplerReport";
// import { Dashboard } from "../pages/Dashboard/Dashboard";

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AspirantReportList />} />
      <Route
        path="/counselling-sessions"
        element={<CounsellingSessionList />}
      />
      <Route path="/ordered-products" element={<AspirantReportList />} />
      <Route path="/ordered-products/:opId" element={<AspirantReport />} />
      <Route
        path="/ordered-products/:opId/aspirant-basic-info"
        element={<AspirantBasicInfo />}
      />
      <Route
        path="/ordered-products/:opId/personality-sampler-report"
        element={<AspirantSamplerReport />}
      />
      <Route
        path="/ordered-products/:opId/aspirant-career-paths"
        element={<AspirantCareerPaths />}
      />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
