import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton } from '@mantine/core';
import { TablerIcon, IconChevronLeft, IconChevronRight } from '@tabler/icons';

import { Tooltip } from "components/Tooltip";
import { useStyles } from './styles';

interface LinksGroupProps {
  icon: TablerIcon;
  label: string;
  initiallyOpened?: boolean;
  link?: string;
  links?: { label: string; link: string }[];
  collapse?: boolean;
}

export default function LinksGroup({ icon: Icon, label, initiallyOpened, links, link = '/', collapse }: LinksGroupProps) {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const hasLinks = Array.isArray(links) && links.length > 0;
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;
  const items = (hasLinks ? links : []).map((link) => (
    <Link to={link.link} className={classes.link}>
      <Text
        component="span"
        key={link.label}
      >
        {link.label}
      </Text>
    </Link>
  ));

  const handleButtonClick = () => {
    if(hasLinks) {
      setOpened((o) => !o)
      return
    }

    navigate(link)
  }

  return (
    <>
      <Tooltip label={label} hideLabel={!collapse}>
        <UnstyledButton onClick={handleButtonClick} className={classes.control}>
          <Group position="apart" spacing={0}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ThemeIcon variant="light" size={30}>
                <Icon size={18} />
              </ThemeIcon>
              {!collapse && <Box ml="md">{label}</Box>}
            </Box>
            {!collapse && hasLinks && (
              <ChevronIcon
                className={classes.chevron}
                size={14}
                stroke={1.5}
                style={{
                  transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
                }}
              />
            )}
          </Group>
        </UnstyledButton>
      </Tooltip>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : <></>}
    </>
  );
}