import { Button, Select, Table, Tooltip } from "@mantine/core";
import { AspirantReport } from "..";
import {
  CALL_STATUS,
  ORDERED_PRODUCT_STATUS,
  POPUP_DETAILS,
  REPORT_STATUS,
} from "../ReportList";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/app-routes";
import { formatPurchasedDate } from "utils/date";
import {
  IconArrowsSort,
  IconEye,
  IconNotebook,
  IconSchool,
  IconSortAscending,
  IconSortDescending,
  IconUpload,
} from "@tabler/icons";
import useStyles from "./styles";

interface Props {
  data: AspirantReport[];
  setOpenPopup: (_: { id?: number; [key: string]: unknown }) => void;
  sort: string;
  sortView: string;
  changeSortValue: (_: any) => void;
}
const getStyle = (disabled: boolean) => {
  if (disabled) {
    return {
      color: "#5453ac",
      opacity: "0.5",
      "pointer-events": "none",
    };
  } else return { color: "#5453ac", cursor: "pointer" };
};
const OrderedProductList = ({
  data,
  setOpenPopup,
  sort,
  sortView,
  changeSortValue,
}: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleActionClick = (
    action: string,
    id: string,
    opId: string,
    aspirantProfile: any
  ) => {
    if (action === "view-pdf") {
      navigate(`${APP_ROUTES.aspirantReport(id).href}`, {
        state: {
          opId,
          aspirantProfile,
        },
      });
      return;
    }

    if (action === "view-sampler-report") {
      navigate(`${APP_ROUTES.samplerReport(id).href}`, {
        state: {
          opId,
          aspirantProfile,
        },
      });
      return;
    }

    if (action === "basic-info") {
      navigate(`${APP_ROUTES.aspirantBasicInfo(id).href}`, {
        state: {
          opId,
          aspirantProfile,
        },
      });
      return;
    }

    if (action === "career-paths") {
      navigate(`${APP_ROUTES.aspirantCareerPaths(id).href}`, {
        state: {
          opId,
          aspirantProfile,
        },
      });
      return;
    }
  };

  const getSortIcon = (value: string) => {
    if (sort === value) {
      return sortView === "ASC" ? (
        <IconSortAscending
          onClick={() => changeSortValue({ sortView: "DESC" })}
        />
      ) : (
        <IconSortDescending
          onClick={() => changeSortValue({ sortView: "ASC" })}
        />
      );
    }
    return (
      <IconArrowsSort
        className="disabled"
        onClick={() => changeSortValue({ sort: value })}
      />
    );
  };
  return (
    <Table className={classes.dataTable}>
      <thead>
        <tr>
          <th>#</th>
          <th>User Info {getSortIcon("aspirantProfile.name")}</th>
          <th>Created On {getSortIcon("orderedProduct.createdAt")}</th>
          <th>Role</th>
          <th>Ordered Product</th>
          <th>OP no.</th>
          <th>Order Status</th>
          <th>Call Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map(
          (
            {
              id,
              orderedProductNumber,
              orderedProductId,
              aspirantProfile,
              orderedProductName,
              orderedProductConfiguration,
              reportStatus,
              callAssessmentStatus,
              orderedProductStatus,
              orderedProductCreatedAt,
            }: AspirantReport,
            index: number
          ) => (
            <tr key={id}>
              <td>{index + 1}</td>
              <td
                style={{
                  minWidth: "200px",
                  width: "200px",
                  wordBreak: "break-all",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  {`${aspirantProfile?.firstName || ""} ${
                    aspirantProfile?.lastName || ""
                  }`}
                </span>
                <span style={{ display: "flex", color: "#4a5157" }}>
                  {aspirantProfile?.email}
                </span>
                <Button
                  variant="white"
                  styles={{
                    label: {
                      color: "#5453ac",
                      fontWeight: "bold",
                    },
                    root: {
                      padding: "0",
                      height: "max-content",
                    },
                  }}
                  onClick={() =>
                    handleActionClick(
                      "basic-info",
                      id,
                      orderedProductId,
                      aspirantProfile
                    )
                  }
                >
                  View Info
                </Button>
              </td>
              <td
                style={{
                  width: "150px",
                  minWidth: "150px",
                }}
              >
                {formatPurchasedDate(orderedProductCreatedAt)}
              </td>
              <td>{aspirantProfile?.customerInfo?.role || ""}</td>
              <td>{orderedProductName}</td>
              <td>{orderedProductNumber}</td>
              <td>
                <Select
                  data={[
                    {
                      value: ORDERED_PRODUCT_STATUS.IN_PROGRESS,
                      label: "In Progress",
                      hidden: true,
                    },
                    {
                      value: ORDERED_PRODUCT_STATUS.COMPLETED,
                      label: "Completed",
                    },
                    {
                      value: ORDERED_PRODUCT_STATUS.NEW,
                      label: "New",
                      hidden: true,
                    },
                  ]}
                  value={orderedProductStatus}
                  styles={{
                    root: { width: "150px" },
                    input: {
                      color: "black",
                      "&:disabled": {
                        color: "black",
                      },
                    },
                  }}
                  disabled={
                    orderedProductStatus !== ORDERED_PRODUCT_STATUS.IN_PROGRESS
                  }
                  onChange={(value) => {
                    setOpenPopup({
                      id: POPUP_DETAILS.OP_STATUS_VERIFY,
                      data: {
                        id,
                        orderedProductId,
                        aspirantProfile,
                      },
                    });
                  }}
                />
              </td>
              <td>
                {callAssessmentStatus && (
                  <Select
                    disabled={callAssessmentStatus !== CALL_STATUS.IN_PROGRESS}
                    onChange={(value) => {
                      setOpenPopup({
                        id: POPUP_DETAILS.CALL_VERIFY,
                        data: {
                          status: value,
                          id,
                          orderedProductId,
                          aspirantProfile,
                        },
                      });
                    }}
                    data={[
                      {
                        value: CALL_STATUS.IN_PROGRESS,
                        label: "Started",
                        hidden: true,
                      },
                      {
                        value: CALL_STATUS.COMPLETED,
                        label: "Completed",
                      },
                      {
                        value: CALL_STATUS.CANCELLED,
                        label: "Cancelled",
                      },
                      {
                        value: CALL_STATUS.REPORT_GENERATED,
                        label: "Report Generated",
                        hidden: true,
                      },
                    ]}
                    value={callAssessmentStatus}
                    styles={{
                      root: { width: "160px" },
                      input: {
                        color: "black",
                        "&:disabled": {
                          color: "black",
                        },
                      },
                    }}
                  />
                )}
              </td>
              <td>
                <div style={{ display: "flex", gap: "12px" }}>
                  <Tooltip withArrow label="View Report">
                    <div>
                      <IconEye
                        size={"25px"}
                        onClick={() =>
                          handleActionClick(
                            callAssessmentStatus
                              ? "view-sampler-report"
                              : "view-pdf",
                            id,
                            orderedProductId,
                            aspirantProfile
                          )
                        }
                        style={getStyle(reportStatus !== REPORT_STATUS.READY)}
                      />
                    </div>
                  </Tooltip>
                  {!!callAssessmentStatus && (
                    <Tooltip
                      withArrow
                      label={
                        callAssessmentStatus === CALL_STATUS.REPORT_GENERATED
                          ? "Re-upload Report"
                          : "Upload Report"
                      }
                    >
                      <div>
                        <IconUpload
                          size={"25px"}
                          onClick={() => {
                            setOpenPopup({
                              id: POPUP_DETAILS.UPLOAD_REPORT,
                              data: { id, orderedProductId, aspirantProfile },
                            });
                          }}
                          style={getStyle(
                            !(
                              callAssessmentStatus === CALL_STATUS.COMPLETED ||
                              callAssessmentStatus ===
                                CALL_STATUS.REPORT_GENERATED
                            )
                          )}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {orderedProductConfiguration.some(
                    (config: any) => config["careerPaths"]?.isActive === true
                  ) && (
                    <Tooltip withArrow label="Career Paths">
                      <div>
                        <IconSchool
                          size={"25px"}
                          onClick={() =>
                            handleActionClick(
                              "career-paths",
                              id,
                              orderedProductId,
                              aspirantProfile
                            )
                          }
                          style={getStyle(reportStatus !== REPORT_STATUS.READY)}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {orderedProductConfiguration.some(
                    (config: any) =>
                      config["executiveSummary"]?.isActive === true
                  ) && (
                    <Tooltip withArrow label="Executive Summary">
                      <div>
                        <IconNotebook
                          size={"25px"}
                          style={getStyle(reportStatus !== REPORT_STATUS.READY)}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};

export default OrderedProductList;
