import { BlockNoteEditor } from "@blocknote/core"
import { BlockNoteView, useBlockNote } from "@blocknote/react"
import "@blocknote/core/style.css"
import { Box, Button } from "@mantine/core"
import { useState } from "react"

interface EditorProps {
  onSave: (content: string) => void
  loading: boolean
  savedContent: string
}

const Editor = ({ onSave, loading, savedContent }: EditorProps) => {
  const [content, setContent] = useState<string>("")

  const editor: BlockNoteEditor | null = useBlockNote({
    initialContent: JSON.parse(savedContent || "[]"),
    onEditorContentChange: (editor: BlockNoteEditor) => {
      setContent(JSON.stringify(editor.topLevelBlocks))
    },
  })

  return (
    <Box>
      <Box sx={{ padding: "10px", background: "white", borderRadius: 8 }}>
        <BlockNoteView editor={editor} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", marginTop: 20 }}>
        <Button onClick={() => onSave(content)} loading={loading}>
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}

export default Editor
