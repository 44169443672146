import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  dataTable: {
    "& th": {
      backgroundColor: "#f5f5f5",
      fontSize: "15px !important",
    },
    "& td": {
      verticalAlign: "top",
      fontSize: "15px !important",
    },
  },
}));

export default useStyles;
