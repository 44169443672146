import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Navbar,
  Group,
  ScrollArea,
  UnstyledButton,
  Box,
  ThemeIcon,
} from "@mantine/core";
import {
  IconNotes,
  IconLogout,
  IconCalendarTime,
  IconArrowRight,
} from "@tabler/icons";

import { Tooltip } from "components/Tooltip";
import LinksGroup from "./LinksGroup";
import { useStyles } from "./styles";

const mockdata = [
  {
    label: "Counselling Sessions",
    icon: IconCalendarTime,
    link: "/counselling-sessions",
  },
  {
    label: "Ordered Products",
    icon: IconNotes,
    link: "/ordered-products",
  },
];

export function NavbarNested() {
  const [collapse, setCollapse] = useState<boolean>(true);
  const { classes } = useStyles();
  const { logout } = useAuth0();

  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} collapse={collapse} />
  ));

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Navbar
      height={"100vh"}
      width={{ base: collapse ? 63 : 300 }}
      p="md"
      className={classes.navbar}
    >
      <Navbar.Section className={classes.header}>
        <Group position="apart">
          <img
            src={collapse ? "/favicon.png" : "/genleap-logo.png"}
            height="30px"
            style={{ margin: "auto" }}
            alt="GENLEAP logo"
          />
        </Group>
        <div
          className={classes.collapseButton}
          onClick={() => {
            setCollapse((o) => !o);
          }}
        >
          <Tooltip
            label={collapse ? "Show Sidebar" : "Hide Sidebar"}
            position="right"
          >
            <ThemeIcon
              variant="filled"
              size={30}
              radius="xl"
              style={{
                transform: collapse ? "rotate(0deg)" : "rotate(180deg)",
              }}
            >
              <IconArrowRight size={18} />
            </ThemeIcon>
          </Tooltip>
        </div>
      </Navbar.Section>

      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UnstyledButton onClick={handleLogout} className={classes.control}>
          <Group position="apart" spacing={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip label="Logout" hideLabel={!collapse}>
                <ThemeIcon variant="light" size={30}>
                  <IconLogout size={18} />
                </ThemeIcon>
              </Tooltip>
              {!collapse && <Box ml="md">Logout</Box>}
            </Box>
          </Group>
        </UnstyledButton>
      </Navbar.Section>
    </Navbar>
  );
}
