import { Box, Table, Title, Modal } from "@mantine/core";
import { ReactElement } from "react";
import { useStyles } from "./styles";

export interface ICareerPath {
  name: string;
  info: { key: string; value: string }[];
}

interface ICareerPathListProps {
  careerPaths: ICareerPath[];
  preview: boolean;
  onClose: any;
}

/**
 * Renders CareerPathList component
 *
 * @param {ICareerPathListProps} props - Injected props from parent
 * @return {ReactElement} React component
 */
export default function CareerPathList({
  careerPaths,
  preview,
  onClose,
}: ICareerPathListProps): ReactElement {
  const { classes } = useStyles();

  return (
    <Modal
      opened={preview}
      onClose={onClose}
      title="Preview"
      withCloseButton={true}
      className={classes.modalTitle}
      size="80%"
    >
      {careerPaths.map(({ name, info }, index) => (
        <Box key={`career-path-${index}`} className={classes.box}>
          <Title variant="text" className={classes.title}>
            {`${index + 1}. `} {name}
          </Title>
          <Table>
            <tbody>
              {info?.map((data, index) => (
                <tr key={index} className={classes.tr}>
                  <td className={classes.td}>{data.key}</td>
                  <td className={classes.td}>{data.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      ))}
    </Modal>
  );
}
