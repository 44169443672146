import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AspirantCard } from "components/AspirantCard";
import { useStyles } from "./styles";
import { useLayoutContext } from "contexts/main-layout-context";
import Editor from "components/Editor";
import { useAspirant } from "hooks/use-aspirant";

export const AspirantBasicInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { aspirantProfile }: any = location.state;

  const pageTitle = `Customer/Aspirant Info`;

  useLayoutContext({
    pageTitle,
    goBackButtonLink: "/ordered-products",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const { classes } = useStyles();
  const { updateAspirantInfo } = useAspirant();

  const handleSave = async (content: string) => {
    setLoading(true);
    await updateAspirantInfo({
      profileId: aspirantProfile.id,
      data: content,
    }).catch((err) => {
      console.error(err);
    });
    setLoading(false);
    navigate(location.pathname, {
      state: {
        aspirantProfile: { ...aspirantProfile, info: { doc: content } },
      },
    });
  };

  return (
    <div className={classes.container}>
      <AspirantCard aspirantProfile={aspirantProfile} />
      <Editor
        savedContent={aspirantProfile.info?.doc}
        onSave={handleSave}
        loading={loading}
      />
    </div>
  );
};
