import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  careerPathContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",

    "& .mantine-InputWrapper-root": {
      width: "80%",
    },
  },
  tableWrapper: {
    border: "1px solid #dee2e6",
    borderRadius: 5,

    "& > div": {
      borderTop: "1px solid #dee2e6",
      padding: "5px",
    },
  },
}))
