import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  box: {
    padding: 25,
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.06)",
    border: "solid 1px #f5f2f2",
    marginBottom: 18,
  },

  modalTitle: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 16,
    display: "flex",
  },

  title: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 16,
    display: "flex",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  tr: {
    borderBottom: "1px",
    "td:first-child": {
      width: "25%",
    },
  },
  td: {
    fontWeight: 400,
    fontSize: "15px !important",
    padding: "16px !important",
    display: "table-cell !important",
    textAlign: "left",
  },
}));
