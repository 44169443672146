import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";

import { useStyles } from "./styles";
import { useLayoutContext } from "contexts/main-layout-context";
import { getConfig } from "config";
import { useAuth0 } from "@auth0/auth0-react";

const FRONTEND_APP_URL = getConfig().FRONTEND_APP_URL;

export const AspirantReport = () => {
  const location = useLocation();
  const { opId, aspirantProfile }: any = location.state;

  const fullName = aspirantProfile.firstName + " " + aspirantProfile.lastName;

  const pageTitle = aspirantProfile.firstName
    ? `GenDiscover Report for ${fullName}`
    : "GenDiscover Report";

  useLayoutContext({
    pageTitle,
    goBackButtonLink: "/ordered-products",
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>();
  const { classes } = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // get token from auth0 to send in front-end url to authenticate view report api
    const getToken = async () => {
      const tokenPromise = getAccessTokenSilently().catch(() => {
        setToken("");
        return "";
      });
      const _token = await tokenPromise;
      setToken(_token);
    };
    getToken();
  });

  return (
    <div className={classes.iframeContainer}>
      <LoadingOverlay visible={loading} />
      {/* <AspirantCard aspirantProfile={aspirantProfile} /> */}
      <iframe
        title="GenDiscover Report"
        src={`${FRONTEND_APP_URL}/customer/ordered-product/${opId}/discovery-report/view-pdf?token=${token}`}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};
