import { createStyles } from "@mantine/core"

export const useStyles = createStyles((theme) => ({
  root: {
    position: "sticky",
    background:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    margin: "-16px",
    marginBottom: 16,
    padding: 20,
    top: 62,
  },

  name: {
    margin: 0,
    marginBottom: 0,
    fontSize: theme.fontSizes.lg,
    textTransform: "capitalize",
  },

  sub: {
    margin: 0,
    marginBottom: 8,
    fontSize: theme.fontSizes.md,
    fontWeight: 400,
    textTransform: "capitalize",
  },
}))
