import { apiManager } from "../api-manager";

import { PRODUCT_LIST } from "../endpoints";

const getAllProducts = async () => {
  const response = await apiManager
    .get(PRODUCT_LIST)
    .then(({ data }) => data)
    .catch((error) => error);
  return response?.data?.map(({ name } = { name: "" }) => name);
};

export const ProductService = {
  getAllProducts,
};
