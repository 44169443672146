import { useDocumentTitle } from "@mantine/hooks";
import React, { useContext, useState } from "react";
import { useLayoutEffect } from "react";
import { createContext } from "react";

export const initialMainLayoutState = {
  pageTitle: "Dashboard",
  goBackButtonLink: "#",
};

type ContextMutationFunctions = {
  setPageTitle: (title: string) => void;
  showGoBackButton: (goBackLink: string) => void;
  hideGoBackButton: () => void;
};

const LayoutContext = createContext<
  typeof initialMainLayoutState & ContextMutationFunctions
  // @ts-ignore-next-line
>(initialMainLayoutState);

export const useLayoutContext = (
  props: Partial<typeof initialMainLayoutState> & {
    hideGoBackButton?: boolean;
    disableTabTitleUpdate?: boolean;
    tabTitle?: string;
  } = {}
) => {
  const layoutContext = useContext(LayoutContext);
  const title = props.tabTitle || props.pageTitle;
  useDocumentTitle(
    !props.disableTabTitleUpdate
      ? (title ? title + " | GENLEAP" : "") || window.document.title
      : window.document.title
  );

  useLayoutEffect(() => {
    if (props.pageTitle) {
      layoutContext.setPageTitle(props.pageTitle);
    }
    if (props.goBackButtonLink) {
      layoutContext.showGoBackButton(props.goBackButtonLink);
    }
    if (props.goBackButtonLink) {
      layoutContext.showGoBackButton(props.goBackButtonLink);
    }
    if (props.hideGoBackButton) {
      layoutContext.hideGoBackButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return layoutContext;
};

export const LayoutProvider = ({ children }: React.PropsWithChildren) => {
  const [layoutInfo, setLayoutInfo] = useState(initialMainLayoutState);

  return (
    <LayoutContext.Provider
      value={{
        ...layoutInfo,

        setPageTitle: (newTitle: string) =>
          setLayoutInfo((data) => ({ ...data, pageTitle: newTitle })),

        showGoBackButton: (goBackLink: string) =>
          setLayoutInfo((data) => ({ ...data, goBackButtonLink: goBackLink })),

        hideGoBackButton: () =>
          setLayoutInfo((data) => ({ ...data, goBackButtonLink: "#" })),
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
