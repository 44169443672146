import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    dark: [
      '#f2f2f9',
      '#d8d7ec',
      '#bdbddf',
      '#a3a2d3',
      '#8988c6',
      '#6f6db9',
      '#5453ac',
      '#474692',
      '#3a3977',
      '#3a3977',
    ],
  },
  primaryColor: 'dark',
  loader: 'oval'
};
