import { Container, Loader, MantineNumberSize } from '@mantine/core';

import { useStyles } from './styles';

interface IContentLoader {
  size?: MantineNumberSize;
  color?: string;
  containerHeight?: string;
}

export function ContentLoader({ size, color, containerHeight = '400px' }: IContentLoader) {
  const { classes } = useStyles();

  return (
    <Container className={classes.root} sx={{ height: containerHeight }}>
      <Loader size={size} color={color}/>
    </Container>
  );
}