import { Container, Title, Text, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';

interface IContentError {
  goBackLink?: string
}

export function ContentError({ goBackLink }: IContentError) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if(goBackLink) {
      navigate(goBackLink)
      return
    }

    navigate(-1)
  }

  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title className={classes.title}>Nothing to see here</Title>
          <Text color="dimmed" size="lg" align="center" className={classes.description}>
            Seems like there is something happened badly. If you think this is an error contact support.
          </Text>
          <Group position="center">
            <Button size="md" onClick={handleGoBack}>Take me back</Button>
          </Group>
        </div>
      </div>
    </Container>
  );
}