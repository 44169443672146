import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  iframeContainer: {
    position: "relative",

    "& > iframe": {
      width: "100%",
      minHeight: "100vh",
      border: 0,
    },
  },
  embedContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      width: "100%",
      [theme.breakpoints.sm]: {
        width: "100%",
      },
      height: "85vh",
      "& > embed": {
        width: "100%",
        height: "100%",
        border: 0,
        alignItems: "center",
      },
    },
  },
}));
