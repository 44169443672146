import { Button, Input, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";

const PAGE_LIMIT = ["10", "20", "30", "40", "50", "100"];

export const DEFAULT_LIMIT = 20;

interface PaginationProps {
  page?: number;
  limit?: number;
  totalPages?: number;
  currentPageCount?: number;
  totalDocs?: number;
  onPaginationChange?: (_page: number, _limit: number) => void;
}
const Pagination = ({
  page = 1,
  limit = DEFAULT_LIMIT,
  totalPages = 1,
  totalDocs = 0,
  currentPageCount = 1,
  onPaginationChange = (_page: number, _limit: number) => {},
}: PaginationProps) => {
  const { classes } = useStyles();
  const [newPageValue, setNewPageValue] = useState(page);
  const gotoPreviousPage = React.useCallback(() => {
    onPaginationChange(page - 1, limit);
    setNewPageValue(page - 1);
  }, [limit, onPaginationChange, page]);

  const gotoNextPage = React.useCallback(() => {
    onPaginationChange(page + 1, limit);
    setNewPageValue(page + 1);
  }, [limit, onPaginationChange, page]);

  const handleLimitChange = React.useCallback(
    (value: string) => {
      setNewPageValue(1);
      onPaginationChange(1, Number(value));
    },
    [onPaginationChange]
  );

  const goToPage = (event: any) => {
    event.target.value = event.target.value.replaceAll(/\D/gi, "");
    setNewPageValue(event.target.value);
  };

  const setPage = (event: any) => {
    if (
      event.key === "Enter" &&
      event.target.value &&
      event.target.value > 0 &&
      event.target.value <= totalPages
    ) {
      onPaginationChange(event.target.value, limit);
    }
  };

  useEffect(() => {
    setNewPageValue(page);
  }, [page]);

  return (
    <div className={classes.paginationContainer}>
      <div className={classes.limitContainer}>
        <span>Show</span>
        <Select
          value={limit?.toString()}
          onChange={handleLimitChange}
          data={PAGE_LIMIT}
        />
        <span>products per page</span>
      </div>
      <div className={classes.limitContainer}>
        {currentPageCount ? (
          <span>
            Showing {(page - 1) * limit + 1 || 0} to{" "}
            {(page - 1) * limit + currentPageCount || 0} of {totalDocs || 0}{" "}
            records
          </span>
        ) : (
          ""
        )}
        <div className={classes.pageInputContainer}>
          <Button
            title="Previous"
            className="cursor-pointer"
            onClick={gotoPreviousPage}
            disabled={page === 1}
          >
            {"<"}
          </Button>
          <span>Page</span>
          <Input onChange={goToPage} onKeyDown={setPage} value={newPageValue} />
          <span>of {totalPages}</span>
          <Button
            title="Next"
            className="cursor-pointer"
            onClick={gotoNextPage}
            disabled={page === totalPages}
          >
            {">"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
