import dayjs from "dayjs";

/**
 * Get age from dateofbrith
 *
 * @param {string|undefined} date
 * @return {number} - Returns an error message
 */
export function getAgeFromDateOfBirth(
  date: string | undefined
): number | undefined {
  if (!date || date === undefined) return undefined;

  const dob = Date.parse(date);
  const monthDifference = Date.now() - dob;
  const ageDate = new Date(monthDifference);
  const ageYear = ageDate.getUTCFullYear();
  return Math.abs(ageYear - 1970);
}

/**
 * Get age from dateofbrith
 *
 * @param {string|undefined} date
 * @return {string} - Returns formated date
 */
export function formatPurchasedDate(date: string | undefined): string {
  if (!date) return "";

  const currentDate = dayjs(date);
  const formattedDate = currentDate.format("D MMM YYYY");

  return formattedDate;
}
