export const getConfig = () => {
  return {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL!,
    API_VERSION: process.env.REACT_APP_API_VERSION!,
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_APP_CLIENT_ID!,
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_TENANT_ISSUER_URL!,
    AUTH0_API_AUDIENCE: process.env.REACT_APP_AUTH0_API_AUDIENCE!,
    FRONTEND_APP_URL: process.env.REACT_APP_FRONTEND_APP_URL!,
  };
};
