import { useLocation } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";

import { useStyles } from "./styles";
import { useLayoutContext } from "contexts/main-layout-context";
import { AspirantService } from "services/aspirant";
import useSWR from "swr";
import { GET_REPORT } from "services/endpoints";

export const AspirantSamplerReport = () => {
  const location = useLocation();
  const { opId, aspirantProfile }: any = location.state;

  const fullName = aspirantProfile.firstName + " " + aspirantProfile.lastName;

  const pageTitle = aspirantProfile.firstName
    ? `Personality Sampler Report for ${fullName}`
    : "Personality Sampler Report";

  useLayoutContext({
    pageTitle,
    goBackButtonLink: "/ordered-products",
  });
  const { classes } = useStyles();

  const { data, isValidating, error } = useSWR(
    GET_REPORT.replace(":opId", opId),
    async () => await AspirantService.getReport({ opId }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return (
    <div className={classes.embedContainer}>
      <div>
        {isValidating ? (
          <LoadingOverlay visible />
        ) : error ? (
          <>Error in getting report</>
        ) : (
          <embed src={data as string} id="pdfViewer" />
        )}
      </div>
    </div>
  );
};
