import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    padding: theme.spacing.lg,
    border: `2px dashed ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    color: theme.colors.dark[8],
    cursor: "pointer",
  },
  uploadInfo: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      fontWeight: "bold",
      margin: "unset",
      marginBottom: "10px",
      fontSize: "20px",
    },
    "& span": {
      fontWeight: "normal",
      color: theme.colors.dark[6],
    },
  },
  footer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  callContainer: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing.lg,
    color: theme.colors.dark[8],
    cursor: "pointer",
    "& p": {
      fontWeight: "bold",
      margin: "unset",
      marginBottom: "10px",
      fontSize: "20px",
    },
  },
}));

export default useStyles;
