import { useState } from "react";
import { Box, Button, Text, Group } from "@mantine/core";

import { AspirantProfile } from "pages/Aspirants";
import { getAgeFromDateOfBirth } from "utils/date";
import { useStyles } from "./styles";

interface AspirantCardProps {
  aspirantProfile: AspirantProfile;
}

export function AspirantCard({ aspirantProfile }: AspirantCardProps) {
  const { classes } = useStyles();
  const [showMore, setShowMore] = useState(true);

  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    education,
    work,
    interests,
    address,
    otherQuestions,
  } = aspirantProfile;

  const getEducationOfAspirant = () => {
    if (education?.school) {
      return [
        `Studying in ${education?.school?.className}`,
        `School name ${education?.school?.schoolName}`,
        education?.school?.stream
          ? `Considered stream - ${education?.school?.stream}`
          : null,
        `Goal - ${education?.school?.goal}`,
      ];
    }
    if (education?.bachelor || education?.master) {
      const keyName = education.bachelor ? "bachelor" : "master";
      return [
        `College - ${education?.[keyName]?.collegeName}`,
        `Career stage - ${education?.[keyName]?.careerPlanning}`,
        `Next step - ${education?.[keyName]?.intention}`,
      ];
    }

    return [];
  };

  const { describeYou, describeYourNeed } =
    education?.school || education?.bachelor || education?.master || work || {};
  return (
    <Box className={classes.root}>
      {aspirantProfile?.customerInfo ? (
        <Box
          sx={{
            marginBottom: 10,
            display: "flex",
            flexFlow: "column",
          }}
        >
          <Text component="h2" className={classes.name}>
            Customer Information
          </Text>
          <Text component="span" className={classes.name}>
            Role : {aspirantProfile?.customerInfo?.role || "-"}
          </Text>
          <Text component="span" className={classes.name}>
            Role Drill Down :{" "}
            {aspirantProfile?.customerInfo?.[
              aspirantProfile?.customerInfo?.role?.toLowerCase() +
                "_role_drill_down"
            ]
              ?.join(", ")
              .toString() || "-"}
          </Text>
        </Box>
      ) : (
        ""
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
        }}
      >
        <Text component="h2" className={classes.name}>
          {firstName}
          {lastName ? ` ${lastName}` : ""}
          {gender ? `, ${gender}` : ""}
          {address?.city ? `, ${address?.city}` : ""}{" "}
          {address?.state ? `(${address?.state})` : ""}
        </Text>
        <Button variant="subtle" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show Less" : "View More"}
        </Button>
      </Box>

      {showMore ? (
        <Group grow align="flex-start">
          <Box>
            <Box>{otherQuestions?.describeYouComment || describeYou}</Box>
            {dateOfBirth && (
              <Box>I am {getAgeFromDateOfBirth(dateOfBirth)} years old</Box>
            )}
            {interests && <Box>I am interested in {interests?.join(", ")}</Box>}
            {otherQuestions?.disabilities && (
              <Box>Disabilities: {otherQuestions.disabilities}</Box>
            )}
            {describeYourNeed && (
              <Box>I want to {describeYourNeed?.join(", ")}</Box>
            )}
          </Box>
          {getEducationOfAspirant().length > 0 && (
            <Box>
              {getEducationOfAspirant().map((item, index) => (
                <Box key={index}>{item}</Box>
              ))}
            </Box>
          )}
          {work && Object.keys(work)?.length > 0 ? (
            <Box>
              <Box>{`Are you employed? - ${work.working}`}</Box>
              <Box>
                {work.working === "Yes"
                  ? `Company name - ${work.companyName}`
                  : null}
              </Box>
              <Box>
                {work.working === "Yes" ? `Job title - ${work.jobTitle}` : null}
              </Box>
              <Box>{`Highest qualification - ${work.qualification}`}</Box>
              <Box>{`Total work experience - ${work.totalWorkExperience}`}</Box>
            </Box>
          ) : null}
        </Group>
      ) : null}
    </Box>
  );
}
