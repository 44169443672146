import { Auth0Provider } from "@auth0/auth0-react";
import { MantineProvider } from "@mantine/core";
import { getConfig } from "./config";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "./Layouts/MainLayout";
import { LayoutProvider } from "contexts/main-layout-context";
import { AccessTokenManager } from "components/AccessTokenManager";
import { theme } from "./theme";

import { NotificationsProvider } from "@mantine/notifications";

export default function App() {
  return (
    <Auth0Provider
      domain={getConfig().AUTH0_DOMAIN}
      clientId={getConfig().AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={getConfig().AUTH0_API_AUDIENCE}
    >
      <AccessTokenManager />
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <NotificationsProvider position="top-right" zIndex={1000}>
          <BrowserRouter>
            <LayoutProvider>
              <MainLayout />
            </LayoutProvider>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
    </Auth0Provider>
  );
}
