import { AspirantService } from "services/aspirant"

import {} from "../../services/gendiscover-reports"

export const useAspirant = () => {
  // TODO: Implement getters for aspirant info

  const updateAspirantInfo = async ({
    profileId,
    data,
  }: {
    profileId: string
    data: string
  }) => {
    return await AspirantService.updateAspirantInfo({
      profileId,
      info: {
        doc: data,
      },
    })
  }

  return {
    updateAspirantInfo,
  }
}
