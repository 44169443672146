import { Routes, Route } from "react-router-dom";

import { useLayoutContext } from "contexts/main-layout-context";
import { EntryPage } from "pages/EntryPage";

export function AuthRoutes() {
  useLayoutContext({
    hideGoBackButton: true,
    pageTitle: 'Login',
    tabTitle: 'Login',
  })

  return (
    <Routes>
      <Route path="/" element={<EntryPage />} />
    </Routes>
  );
}
