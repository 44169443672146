import useSWR from "swr"

import { COUNSELLING_SESSIONS } from "services/endpoints"
import { CounsellingSessionsService } from 'services/counselling-sessions'


export const useCounsellingSessions = () => {
  const { data, error } = useSWR(COUNSELLING_SESSIONS, CounsellingSessionsService.getCounsellingSessions)

  const isLoading = !error && !data ? true : false

  return {
    data,
    error,
    isLoading 
  }
}