import { useAuth0 } from "@auth0/auth0-react";
import { Center, Text } from "@mantine/core";

export function EntryPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <Center style={{ flexDirection: "column" }}>
        <div>
          <Text>Welcome to the GENLEAP's Counsellor App</Text>
        </div>
        <div>
          <Text sx={{ cursor: 'pointer' }} variant="gradient" onClick={loginWithRedirect}>Please login to continue</Text>
        </div>
      </Center>
    </div>
  );
}
