import { Tooltip as MantineTooltip, TooltipProps as MantineTooltipProps } from "@mantine/core";

interface TooltipProps {
  children: React.ReactElement
  label: string;
  hideLabel?: boolean;
}

export function Tooltip({ hideLabel, children, label, ...rest }: TooltipProps & MantineTooltipProps) {

  if(hideLabel) {
    return <>{children}</>
  }

  return <MantineTooltip label={label} position='right' {...rest}>
    {children}
  </MantineTooltip>

}
