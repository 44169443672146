import { apiManager } from '../api-manager';

import { COUNSELLING_SESSIONS, SYNC_ON_MONDAY } from '../endpoints';

const getCounsellingSessions = async () => {
  const response = await apiManager.get(COUNSELLING_SESSIONS);
  return response?.data?.data;
};

const getMondaySync = async (opNumber: any) => {
  const response = await apiManager.post(SYNC_ON_MONDAY(opNumber)).then(({ data }) => data).catch((error) => error);
  return response;

};



export const CounsellingSessionsService = {
  getCounsellingSessions,
  getMondaySync
};