import { getConfig } from "../config";

const config = getConfig();

// eslint-disable-next-line camelcase
const API_VERSION = config.API_VERSION;

const _API_VERSION = API_VERSION || "v1";
const BASE_PATH = `/api/${_API_VERSION}`;

/*********************************\
 * ASPIRANT REPORTS
\*********************************/

export const ASPIRANT_REPORTS = `${BASE_PATH}/report-handler/all`;
export const UPLOAD_REPORT = `${BASE_PATH}/report-handler/upload`;
export const GET_REPORT = `${BASE_PATH}/report-handler/view/:opId`;
export const UPDATE_CALL_STATUS = `${BASE_PATH}/report-handler/change-status`;
export const UPDATE_OP_STATUS = `${BASE_PATH}/ordered-products-handler/update-status/:opid`;

/*********************************\
 * INSIGHT SESSIONS
\*********************************/

export const COUNSELLING_SESSIONS = `${BASE_PATH}/counselling-session`;

/*********************************\
 * ASPIRANT INFO
\*********************************/

export const ASPIRANT_INFO = `${BASE_PATH}/aspirant-profile/additional-info`;
export const SAVE_CAREER_PATHS = `${BASE_PATH}/aspirant-profile/career-paths`;
export const PUBLISH_CAREER_PATHS = `${BASE_PATH}/aspirant-profile/career-paths/publish`;
export const FETCH_CAREER_PATHS = `${BASE_PATH}/aspirant-profile/:profileId/career-paths`;

export const SYNC_ON_MONDAY = (id: any) => {
  return `${BASE_PATH}/user/${id}/backfill-monday-subitem`;
};

/*********************************\
 * PRODUCTS LIST
\*********************************/

export const PRODUCT_LIST = `${BASE_PATH}/products`;
