import { Button, Loader, Modal } from "@mantine/core";
import useStyles from "./uploadReportStyle";
import { IconFileUpload } from "@tabler/icons";
import { useDropzone } from "react-dropzone";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { AspirantService } from "services/aspirant";

const UploadReport = ({
  opened,
  data = {},
  onClose = () => {},
  getData = () => {},
}: {
  opened: boolean;
  data: any;
  onClose: () => void;
  getData: () => void;
}) => {
  const [fileData, setFileData] = useState<
    { formData: any; fileName: string } | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const { aspirantProfile = {}, orderedProductId } = data;
  const fullName = aspirantProfile.firstName + " " + aspirantProfile.lastName;

  const pageTitle = aspirantProfile?.firstName
    ? `Upload Report for ${fullName}`
    : "Upload Report";

  const { classes } = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    maxSize: 25 * 1024 * 1024,
    multiple: false,
    // eslint-disable-next-line consistent-return
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length) {
        const payload = new FormData();
        payload?.append(
          "file",
          acceptedFiles[0],
          "Personality Sampler Report.pdf"
        );
        payload?.append("folder", "reports");
        payload?.append("orderedProductId", orderedProductId);

        setFileData({
          formData: payload,
          fileName: acceptedFiles[0]?.name || "",
        });
      } else {
        showNotification({
          title: "Failed",
          message:
            "Please make sure the file format is PDF (max 25 MB allowed)",
          autoClose: 5000,
          color: "red",
        });
      }
    },
  });

  useEffect(() => {
    setFileData(undefined);
    setLoading(false);
  }, [opened]);

  const uploadReport = async () => {
    if (loading) return;
    setLoading(true);
    const data: any = await AspirantService.uploadReport({
      formData: fileData?.formData,
    });
    if (data?.success) {
      getData();
      onClose();
      showNotification({
        title: "Success",
        message: "Report Uploaded.",
        autoClose: 5000,
        color: "lime",
      });
    } else {
      showNotification({
        title: "Error",
        message: "Report upload Failed, Please try again.",
        autoClose: 5000,
        color: "red",
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={pageTitle}
      styles={{
        title: { fontWeight: "bold" },
      }}
      size="500px"
    >
      <div className={classes.container} {...getRootProps()}>
        <IconFileUpload size={100} />
        <input {...getInputProps()} />
        <div className={classes.uploadInfo}>
          <p>Upload a file or drag and drop</p>
          <span>
            {/* Please ensure the file is in PDF format (maximum size 25 MB) */}
            Please make sure the file format is PDF (max 25 MB allowed)
          </span>
          <span style={{ color: "green", marginTop: "10px" }}>
            {fileData?.fileName || ""}
          </span>
        </div>
      </div>
      <div className={classes.footer}>
        <Button onClick={uploadReport} disabled={!fileData?.fileName}>
          {loading && (
            <Loader color="white" size={20} style={{ marginRight: "10px" }} />
          )}
          UPLOAD
        </Button>
      </div>
    </Modal>
  );
};

export default UploadReport;
