import { apiManager } from "../api-manager";

import { ASPIRANT_REPORTS } from "../endpoints";

const getAspirantReports = async (data = {}) => {
  const response = await apiManager.post(ASPIRANT_REPORTS, data);
  return response?.data?.data;
};

export const AspirantReportsService = {
  getAspirantReports,
};
