import useSWR from "swr";

import { PRODUCT_LIST } from "../../services/endpoints";
import { AspirantReportsService } from "../../services/gendiscover-reports";
import { ProductService } from "services/product";
import { useEffect, useState } from "react";
import { DEFAULT_LIMIT } from "components/Pagination";

export const useAspirantReports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    reportStatus: "",
    orderedProductStatus: "",
    productName: "",
    barcodeStatus: "",
    startUpdatedAt: "",
    endUpdatedAt: "",
    sort: "orderedProduct.createdAt",
    sortView: "DESC",
  });
  const [paginator, setPaginator] = useState<{
    page: number;
    limit: number;
    totalPages: number;
    totalDocs: number;
  }>();

  const getProductsData = async (page?: number, limit?: number) => {
    setLoading(true);
    await AspirantReportsService.getAspirantReports({
      configurationData: ["careerPaths", "executiveSummary"],
      page: page || 1,
      limit: limit || paginator?.limit || DEFAULT_LIMIT,
      search: search,
      searchColumns: [
        "aspirantProfile.name",
        "orderedProduct.orderedProductNumber",
        "product.name",
        "user.email",
      ],
      filter: {
        reportStatus: filter.reportStatus || undefined,
        orderedProductStatus: filter.orderedProductStatus || undefined,
        productName: filter.productName || undefined,
        barcodeStatus: filter.barcodeStatus || undefined,
        startUpdatedAt: filter.startUpdatedAt || undefined,
        endUpdatedAt: filter.endUpdatedAt || undefined,
      },
      sort: {
        [filter.sort]: filter.sortView,
      },
    }).then(({ docs, ...paginator }) => {
      setPaginator(paginator);
      setData(docs);
      setLoading(false);
    });
  };

  useEffect(() => {
    getProductsData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter]);

  const { data: products } = useSWR(
    PRODUCT_LIST,
    ProductService.getAllProducts,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  );
  return {
    data,
    isLoading: loading,
    products,
    search,
    setSearch,
    paginator,
    getProductsData,
    filter,
    setFilter,
  };
};
