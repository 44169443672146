import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  container: {
    background:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    padding: 20,
  },
  careerPathContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  tableWrapper: {
    border: "1px solid #dee2e6",
    borderRadius: 5,

    "& > table tr": {
      borderBottom: "1px solid #dee2e6",
    },

    "& th": {
      borderRight: "1px solid #dee2e6",
    },

    "& td": {
      borderRight: "1px solid #dee2e6",
    },

    "& > div": {
      // borderTop: "1px solid #dee2e6",
      padding: "5px",
    },
  },
}));
