import { useState } from "react";
import dayjs from "dayjs";
import { Table, Box, Input } from "@mantine/core";

import { ContentError } from "components/ContentError";
import { useCounsellingSessions } from "hooks/use-counselling-sessions";
import { CounsellingSession } from "./index.d";
import { ContentLoader } from "components/ContentLoader";
import { useLayoutContext } from "contexts/main-layout-context";
import useStyles from "./styles";

export function CounsellingSessionList() {
  const {
    data: counsellingSessions = [],
    error,
    isLoading,
  } = useCounsellingSessions();
  const [search, setSearch] = useState("");
  const { classes } = useStyles();
  useLayoutContext({
    hideGoBackButton: true,
    pageTitle: "Counselling Sessions",
  });

  if (error) {
    return <ContentError goBackLink="/" />;
  }

  if (isLoading) {
    return <ContentLoader />;
  }

  const rows =
    (search
      ? counsellingSessions?.filter(
          ({ firstName, lastName, orderedProductNumber }: CounsellingSession) =>
            firstName.search(RegExp(search, "i")) !== -1 ||
            lastName.search(RegExp(search, "i")) !== -1 ||
            orderedProductNumber.search(RegExp(search, "i")) !== -1
        ) || []
      : counsellingSessions
    )?.map(
      ({
        orderedProductNumber,
        firstName,
        lastName,
        sessionBookedOnDate,
        type,
      }: CounsellingSession) => (
        <tr key={orderedProductNumber + type}>
          <td>{firstName}</td>
          <td>{lastName}</td>
          <td>{orderedProductNumber}</td>
          <td>{dayjs(sessionBookedOnDate).format("DD MMM YYYY, HH:mm A")}</td>
        </tr>
      )
    ) || "";

  return (
    <Box
      sx={{
        padding: 16,
        background: "white",
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Input
          placeholder="Search"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          wrapperProps={{ style: { maxWidth: 300 } }}
        />
      </Box>
      <Table className={classes.dataTable}>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Ordered Product #</th>
            <th>Event Created Date</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tbody>
          {rows.length === 0 && <tr>No counselling session found</tr>}
        </tbody>
      </Table>
    </Box>
  );
}
