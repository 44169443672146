import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    color: "#4a5157",
  },
  limitContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& input": {
      width: "80px",
    },
  },
  pageContainer: {
    display: "flex",
    alignItems: "center",
  },
  pageInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "& input": {
      width: "40px",
    },
  },
}));
