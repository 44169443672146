import { Button, Loader, Modal } from "@mantine/core";
import useStyles from "../AspirantReport/uploadReportStyle";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { AspirantService } from "services/aspirant";
import { CALL_STATUS } from "../ReportList";

const CallStatus = ({
  opened,
  data = {},
  onClose = () => {},
  getData = () => {},
}: {
  opened: boolean;
  data: any;
  onClose: () => void;
  getData: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { aspirantProfile = {}, orderedProductId, status } = data;
  const fullName = aspirantProfile.firstName + " " + aspirantProfile.lastName;

  const pageTitle = aspirantProfile?.firstName
    ? `Change the call status for ${fullName}`
    : "Change the call status";

  const { classes } = useStyles();

  const handleCallStatus = async () => {
    if (loading) return;
    setLoading(true);
    const data: any = await AspirantService.updateCallStatus({
      data: {
        orderedProductId: orderedProductId,
        callAssessmentStatus: status,
      },
    });
    if (data?.success) {
      getData();
      onClose();
      showNotification({
        title: "Success",
        message: "Call status updated successfully.",
        autoClose: 5000,
        color: "lime",
      });
    } else {
      showNotification({
        title: "Error",
        message: "Status update failed, Please try again.",
        autoClose: 5000,
        color: "red",
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={pageTitle}
      styles={{
        title: { fontWeight: "bold" },
      }}
      size="lg"
    >
      <div className={classes.callContainer}>
        <p>
          {status === CALL_STATUS.CANCELLED
            ? "Are you sure you want to mark the call as CANCELLED?"
            : "Are you sure you want to mark the call as COMPLETED?"}
        </p>
      </div>
      <div className={classes.footer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCallStatus}>
          {loading && (
            <Loader color="white" size={20} style={{ marginRight: "10px" }} />
          )}
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default CallStatus;
