import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Tooltip } from "@mantine/core";

import { useStyles } from "./styles";
import { useLayoutContext } from "contexts/main-layout-context";
import { IconPlus } from "@tabler/icons";
import { AspirantCareerPath } from "./CareerPath";
import { AspirantService } from "../../../services/aspirant/index";
import CareerPathList from "components/CareerPathList";
import { showNotification } from "@mantine/notifications";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";

const sanitizeCareerPaths = (careerPaths: any[]) => {
  return careerPaths
    .filter((careerPath) => !careerPath.deleted)
    .map((data, index) => ({ ...data, sequence: index + 1 }));
};

export const AspirantCareerPaths = () => {
  const [careerPaths, setCareerPaths] = useState<any[]>([]);
  const [savedAsDraft, setSavedAsDraft] = useState<boolean>(false);
  const [published, setPublished] = useState<boolean>(false);
  const [careerPathsToPreview, setCareerPathsToPreview] = useState<any[]>([]);
  const [preview, setPreview] = useState<boolean>(false);

  const location = useLocation();
  const { aspirantProfile, opId }: any = location.state;

  const fullName = aspirantProfile.firstName + " " + aspirantProfile.lastName;

  const pageTitle = "Aspirant Career Paths: " + fullName;

  useLayoutContext({
    pageTitle,
    goBackButtonLink: "/ordered-products",
  });

  useEffect(() => {
    const fetchCareerPaths = async () => {
      const response = await AspirantService.getCareerPaths({
        profileId: aspirantProfile.id,
        opId,
      });

      if (Array.isArray(response)) {
        setCareerPaths(
          response.map(({ id, name, info }, index) => ({
            id,
            name,
            info,
            sequence: index + 1,
          }))
        );
        setCareerPathsToPreview(
          response.map(({ id, name, info }, index) => ({
            id,
            name,
            info,
            sequence: index + 1,
          }))
        );
        setSavedAsDraft(response[0] ? response[0].status === "DRAFT" : true);
        setPublished(response[0] ? response[0].status === "PUBLISH" : true);
      }
    };

    fetchCareerPaths();
  }, [aspirantProfile.id, opId]);

  const handleChange = (data: any, index: number) => {
    const newCareerPaths = [...careerPaths];

    if (data.deleted) {
      newCareerPaths.splice(index, 1);
      setCareerPaths(
        newCareerPaths.map((data, index) => ({ ...data, sequence: index + 1 }))
      );
    } else {
      newCareerPaths[index] = data;
      setCareerPaths(newCareerPaths);
    }
    setSavedAsDraft(false);
    setPublished(false);
  };

  const handleSaveAsDraft = async () => {
    if (published) return;

    const response = await AspirantService.saveCareerPathsAsDraft({
      profileId: aspirantProfile.id,
      opid: opId,
      careerPaths: sanitizeCareerPaths(careerPaths),
    });

    if (response) {
      setSavedAsDraft(true);
      setCareerPathsToPreview(careerPaths);
    }
  };

  const handlePublish = async () => {
    if (!savedAsDraft) return;
    let message = "";
    if (careerPaths?.length) {
      careerPaths.some(({ info, name }) => {
        if (!name) {
          message = "Please provide Career path title, it cannot be blank.";
          return true;
        }
        if (info?.length) {
          return info.some(({ key, value }: { key: string; value: string }) => {
            if (!key) {
              message = `Please provide values for "Key" under "${name}". It cannot be blank.`;
              return true;
            }
            if (!value) {
              message = `Please provide value for key "${key}" under "${name}". It cannot be blank.`;
              return true;
            }
            return false;
          });
        } else {
          message = `Please add at least one career path for "${name}".`;
          return true;
        }
      });
    }

    if (message) {
      showNotification({
        title: "Error!",
        message: message,
        autoClose: 5000,
        color: "red",
      });
    } else {
      const response = await AspirantService.publishCareerPaths({
        profileId: aspirantProfile.id,
        opid: opId,
      });

      if (response) {
        setPublished(true);
      }
    }
  };
  const handlePreview = () => {
    setPreview(!preview);
  };

  //  tooltip of publish button according to save as draft and publish
  const publishBtnLabel = () => {
    if (published) return "Published";
    if (savedAsDraft) return "You can't make any changes after published";
    return "Save as draft first to publish";
  };

  // tooltip of preview button according to save as draft and blank data
  const previewBtnLabel = () => {
    if (!savedAsDraft && !published)
      return "Save as draft to preview latest changes";
    if (!careerPathsToPreview.length) return "Nothing to preview";
    return "Click to preview";
  };

  const { classes } = useStyles();
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index)
      return;

    const newCareerPaths = [...careerPaths];
    const removedItem = newCareerPaths.splice(result.source.index, 1)[0];
    newCareerPaths.splice(result.destination.index, 0, removedItem);
    const updatedCareerPaths = newCareerPaths.map((data, index) => ({
      ...data,
      sequence: index + 1,
    }));

    setCareerPaths(updatedCareerPaths);
    setSavedAsDraft(false);
    setPublished(false);
  };
  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="group">
          {(provider) => (
            <div
              {...provider.droppableProps}
              ref={provider.innerRef}
              id="droppable"
            >
              {careerPaths.map((careerPath, index) => (
                <Draggable
                  draggableId={careerPath.id ?? careerPath.tempId}
                  key={careerPath.id ?? careerPath.tempId}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={careerPath.id ?? careerPath.tempId}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      <AspirantCareerPath
                        savedCareerPath={careerPath}
                        onChange={(data) => handleChange(data, index)}
                        dragHandle={
                          {
                            ...provided.dragHandleProps,
                          } as DraggableProvidedDragHandleProps
                        }
                        droppableId={careerPath.id ?? careerPath.tempId}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box
        sx={{
          paddingLeft: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          leftIcon={<IconPlus />}
          variant="outline"
          color="gray"
          size="sm"
          onClick={() =>
            handleChange(
              {
                name: "",
                info: [],
                tempId: Date.now().toString(),
                sequence: careerPaths.length + 1,
              },
              careerPaths.length
            )
          }
        >
          Add New Career Path
        </Button>
        <Box sx={{ display: "flex", gap: 20 }}>
          <Button
            variant="outline"
            color="primary"
            size="sm"
            disabled={savedAsDraft || published}
            onClick={handleSaveAsDraft}
          >
            Save as Draft
          </Button>
          <Tooltip label={previewBtnLabel()}>
            <Box>
              <Button
                variant="outline"
                color="green"
                size="sm"
                disabled={!careerPathsToPreview.length}
                onClick={handlePreview}
              >
                {"Preview"}
              </Button>
            </Box>
          </Tooltip>
          <Tooltip label={publishBtnLabel()}>
            <Box>
              <Button
                variant="outline"
                color="green"
                size="sm"
                disabled={!savedAsDraft || published}
                onClick={handlePublish}
              >
                {published ? "Published" : "Publish"}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <CareerPathList
        preview={preview}
        careerPaths={sanitizeCareerPaths(careerPathsToPreview)}
        onClose={handlePreview}
      />
    </div>
  );
};
