import { useEffect, useState } from "react";
import { Box, Button, Table, TextInput } from "@mantine/core";

import { useStyles } from "./styles";
import {
  IconCaretDown,
  IconDragDrop,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";

export interface CareerPath {
  name: string;
  info: {
    key: string;
    value: string;
  }[];
  deleted?: boolean;
}

interface AspirantCareerPathProps {
  savedCareerPath: CareerPath;
  onChange: (careerPath: CareerPath) => void;
  dragHandle: DraggableProvidedDragHandleProps;
  droppableId: string;
}

export const AspirantCareerPath = ({
  savedCareerPath,
  onChange,
  dragHandle,
  droppableId,
}: AspirantCareerPathProps) => {
  const [careerPath, setCareerPath] = useState<CareerPath>(savedCareerPath);
  const [collapse, setCollapse] = useState<boolean>(false);

  useEffect(() => {
    onChange(careerPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerPath]);

  const updateTitle = (e: any) => {
    setCareerPath({ ...careerPath, name: e.target.value });
  };

  const updateKey = (e: any, index: number) => {
    const newDetails = [...careerPath.info];
    newDetails[index].key = e.target.value;
    setCareerPath({ ...careerPath, info: newDetails });
  };

  const updateValue = (e: any, index: number) => {
    const newDetails = [...careerPath.info];
    newDetails[index].value = e.target.value;
    setCareerPath({ ...careerPath, info: newDetails });
  };

  const addKey = () => {
    setCareerPath({
      ...careerPath,
      info: [...careerPath.info, { key: "", value: "" }],
    });
  };

  const removeKey = (index: number) => {
    const newDetails = [...careerPath.info];
    newDetails.splice(index, 1);
    setCareerPath({ ...careerPath, info: newDetails });
  };

  const removeCareerPath = () => {
    onChange({ name: "", info: [], deleted: true });
  };

  const { classes } = useStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index)
      return;
    const newDetails = [...careerPath.info];
    const removedItem = newDetails.splice(result.source.index, 1)[0];
    newDetails.splice(result.destination.index, 0, removedItem);
    setCareerPath({ ...careerPath, info: newDetails });
  };

  return (
    <div className={classes.careerPathContainer}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className={classes.title}>
          <IconCaretDown
            color="gray"
            cursor="pointer"
            style={{
              transition: "transform 200ms ease",
              transform: collapse ? "rotate(-90deg)" : "rotate(0deg)",
            }}
            onClick={() => setCollapse((b: boolean) => !b)}
          />
          <TextInput
            placeholder="Career Path Title"
            variant="unstyled"
            size="lg"
            width="80%"
            styles={{
              input: {
                fontWeight: 700,
                fontSize: "1.25rem",
                lineHeight: "1.5rem",
                color: "orange",
              },
            }}
            value={careerPath.name}
            onChange={updateTitle}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
        >
          <div>
            <IconTrash cursor={"pointer"} onClick={removeCareerPath} />
          </div>
          <div {...dragHandle}>
            <IconDragDrop />
          </div>
        </Box>
      </Box>
      {!collapse && (
        <div
          className={classes.tableWrapper}
          style={{ borderLeft: "5px solid orange" }}
        >
          <Table horizontalSpacing="xl" fontSize="md">
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
                <th style={{ width: 100 }}></th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={droppableId} type="">
                {(provider) => (
                  <tbody
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                    id={droppableId}
                  >
                    {careerPath.info?.map(
                      (
                        detail: { key: string; value: string },
                        index: number
                      ) => (
                        <Draggable
                          draggableId={droppableId + index}
                          key={droppableId + index}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              key={droppableId + index}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <td>
                                <TextInput
                                  placeholder="Key Name"
                                  variant="unstyled"
                                  size="md"
                                  value={detail.key}
                                  onChange={(e) => updateKey(e, index)}
                                />
                              </td>
                              <td>
                                <TextInput
                                  placeholder="Value"
                                  variant="unstyled"
                                  size="md"
                                  value={detail.value}
                                  onChange={(e) => updateValue(e, index)}
                                />
                              </td>
                              <td>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 10,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "gray",
                                  }}
                                >
                                  <div>
                                    <IconTrash
                                      cursor={"pointer"}
                                      onClick={() => removeKey(index)}
                                    />
                                  </div>
                                  <div {...provided.dragHandleProps}>
                                    <IconDragDrop />
                                  </div>
                                </Box>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      )
                    )}
                    {provider.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          <div>
            <Button
              leftIcon={<IconPlus />}
              variant="subtle"
              color="lightgray"
              radius="xs"
              size="sm"
              onClick={addKey}
            >
              Add Key
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
