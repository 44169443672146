import { Box, Loader, Select } from "@mantine/core";

import { useAspirantReports } from "../../hooks/use-aspirants-reports";
import { useLayoutContext } from "contexts/main-layout-context";
import { useState } from "react";
import UploadReport from "./AspirantReport/UploadReport";
import CallStatus from "./VerificationModal/callStatus";
import OrderedProductList from "./OrderedProductList";
import useStyles from "./style";
import OrderedProductStatus from "./VerificationModal/orderedProductStatus";
import SearchInput from "components/SearchInput";
import Pagination from "components/Pagination";
import CustomDateRangePicker from "components/DateRangePicker";

interface Status {
  [key: string]: string;
}
export const POPUP_DETAILS = {
  CALL_VERIFY: 1,
  UPLOAD_REPORT: 2,
  OP_STATUS_VERIFY: 3,
};

export const REPORT_STATUS: Status = {
  NOT_READY: "NOT_READY",
  READY: "READY",
};

export const ORDERED_PRODUCT_STATUS: Status = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETE",
};

export const BARCODE_STATUS: Status = {
  ACTIVATED: "ACTIVATED",
  DISPATCHED_TO_LAB: "DISPATCHED_TO_LAB",
};

export const CALL_STATUS: Status = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  REPORT_GENERATED: "REPORT_GENERATED",
  CANCELLED: "CANCELLED",
};

export function AspirantReportList() {
  const { classes } = useStyles();
  const {
    data,
    isLoading,
    products,
    search,
    setSearch,
    getProductsData,
    paginator,
    filter,
    setFilter,
  } = useAspirantReports();

  useLayoutContext({
    hideGoBackButton: true,
    pageTitle: "Ordered Products",
  });

  const [openPopup, setOpenPopup] = useState<{
    id?: number;
    [key: string]: unknown;
  }>({});

  const setValue = (data: any) => {
    setFilter((prev) => ({ ...prev, ...data }));
  };
  return (
    <Box
      sx={{
        padding: 16,
        background: "white",
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 10,
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Select
          placeholder={"Product"}
          data={products || []}
          onChange={(value) => setValue({ productName: value })}
          clearable
          value={filter.productName}
        />
        <Select
          placeholder={"Report Status"}
          data={Object.values(REPORT_STATUS).map((status) => ({
            label: status.replace(/_/g, " "),
            value: status,
          }))}
          value={filter.reportStatus}
          onChange={(value) => setValue({ reportStatus: value })}
          clearable
        />
        <Select
          placeholder={"Ordered Product Status"}
          data={Object.values(ORDERED_PRODUCT_STATUS).map((status) => ({
            label: status.replace(/_/g, " "),
            value: status,
          }))}
          value={filter.orderedProductStatus}
          onChange={(value) => setValue({ orderedProductStatus: value })}
          clearable
        />
        <Select
          placeholder={"Barcode Status"}
          data={Object.values(BARCODE_STATUS).map((status) => ({
            label: status.replace(/_/g, " "),
            value: status,
          }))}
          value={filter.barcodeStatus}
          onChange={(value) => setValue({ barcodeStatus: value })}
          clearable
        />
        <CustomDateRangePicker
          placeholder="Barcode Updated Date"
          value={[filter.startUpdatedAt, filter.endUpdatedAt]}
          onChange={setValue}
          startDateKey={"startUpdatedAt"}
          endDateKey={"endUpdatedAt"}
        />
        <SearchInput search={search} setSearch={setSearch} />
      </Box>

      {isLoading && (
        <div className={classes.loaderOverlay}>
          <Loader />
        </div>
      )}
      <div style={{ width: "100%", overflowX: "auto" }}>
        <OrderedProductList
          data={data}
          setOpenPopup={setOpenPopup}
          sort={filter.sort}
          sortView={filter.sortView}
          changeSortValue={setValue}
        />
      </div>

      <Pagination
        {...paginator}
        currentPageCount={data?.length}
        onPaginationChange={(page, limit) => getProductsData(page, limit)}
      />

      {POPUP_DETAILS.UPLOAD_REPORT === openPopup.id && (
        <UploadReport
          opened={POPUP_DETAILS.UPLOAD_REPORT === openPopup.id}
          data={openPopup.data}
          onClose={() => setOpenPopup({})}
          getData={() => getProductsData(paginator?.page, paginator?.limit)}
        />
      )}
      {POPUP_DETAILS.CALL_VERIFY === openPopup.id && (
        <CallStatus
          opened={POPUP_DETAILS.CALL_VERIFY === openPopup.id}
          data={openPopup.data}
          onClose={() => setOpenPopup({})}
          getData={() => getProductsData(paginator?.page, paginator?.limit)}
        />
      )}
      {POPUP_DETAILS.OP_STATUS_VERIFY === openPopup.id && (
        <OrderedProductStatus
          opened={POPUP_DETAILS.OP_STATUS_VERIFY === openPopup.id}
          data={openPopup.data}
          onClose={() => setOpenPopup({})}
          getData={() => getProductsData(paginator?.page, paginator?.limit)}
        />
      )}
    </Box>
  );
}
