import { apiManager } from "../api-manager";

import {
  ASPIRANT_INFO,
  SAVE_CAREER_PATHS,
  PUBLISH_CAREER_PATHS,
  FETCH_CAREER_PATHS,
  UPLOAD_REPORT,
  GET_REPORT,
  UPDATE_CALL_STATUS,
  UPDATE_OP_STATUS,
} from "../endpoints";

const updateAspirantInfo = async (data: any) => {
  const response = await apiManager.put(ASPIRANT_INFO, data);
  return response?.data?.data;
};

const saveCareerPathsAsDraft = async (data: any) => {
  const response = await apiManager.put(SAVE_CAREER_PATHS, data);
  return response?.data?.data;
};

const publishCareerPaths = async (data: any) => {
  const response = await apiManager.put(PUBLISH_CAREER_PATHS, data);
  return response?.data?.data;
};

const getCareerPaths = async ({ profileId, opId }: any) => {
  const response = await apiManager.get(
    `${FETCH_CAREER_PATHS.replace(":profileId", profileId)}?opid=${opId}`
  );
  return response?.data?.data;
};

const uploadReport = async ({ formData }: any) => {
  const response = await apiManager
    .post(UPLOAD_REPORT, formData, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((data) => data)
    .catch((error) => error);
  return response?.data;
};

const getReport = async ({ opId }: any) => {
  const response = await apiManager
    .get(GET_REPORT.replace(":opId", opId))
    .then(({ data }) => data.data.url);
  return response;
};

const updateCallStatus = async ({ data }: any) => {
  const response = await apiManager
    .post(UPDATE_CALL_STATUS, data)
    .then((data) => data)
    .catch((error) => error);
  return response?.data;
};

const updateOrderedProductStatus = async ({ opid }: any) => {
  const response = await apiManager
    .post(UPDATE_OP_STATUS.replace(":opid", opid))
    .then((data) => data)
    .catch((error) => error);
  return response?.data;
};

export const AspirantService = {
  updateAspirantInfo,
  saveCareerPathsAsDraft,
  publishCareerPaths,
  getCareerPaths,
  uploadReport,
  getReport,
  updateCallStatus,
  updateOrderedProductStatus,
};
